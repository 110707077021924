<template>
  <div class="v-close-button">
    <div class="v-close-button-inner">
      <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="4.988" y="6.362" width="2" height="14" rx="1" transform="rotate(-45 4.988 6.362)" fill="currentColor"/><rect x="14.887" y="4.948" width="2" height="14" rx="1" transform="rotate(45 14.887 4.948)" fill="currentColor"/></svg>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.v-close-button {
  @include media(L) {
    position: fixed;
    bottom: 0;
    height: 150px;
    background: white;
    z-index: 101;
    display: flex;
    justify-content: center;
    padding-top: 60px;
    left: 0;
    right: 0;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 50%);
  }

  &.desktop {
    @include media(L) {
      display: none;
    }
  }
  .v-close-button-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    border-radius: 100%;
    width: 34px;
    height: 34px;
    background: $secondary-dark;
    color: $white;
    transition: background $transition-duration $transition-curve, color $transition-duration $transition-curve;
    @include media(L) {
      position: relative;
      top: auto;
      right: auto;
      width: 50px;
      height: 50px;
      background: $white;
      border: 1px solid $gray;
      color: $gray;
    }

    &:hover {
      background: $secondary;
      @include media(L) {
        background: $gray;
        color: $white;
      }
    }
  }
}



</style>