import $ from 'jquery';

export default (container?: HTMLElement) => {
  // Akkordeon
  const $accordion = $('.accordeon');

  // Prüfen, ob der Parent die Klasse accordeon-first--open hat
  if ($accordion.hasClass('accordeon-first--open')) {
    const $firstItem = $accordion.find('.accordeon--item').first();
    $firstItem.addClass('active');
    $firstItem.children('.accordeon--content').show();
  }

  $('.accordeon--title').click(function () {
    $(this).parent().toggleClass('active');

    if ($(this).hasClass("no--closer")) {
      $(this).siblings('.accordeon--content').slideToggle();
    } else {
      $(this).parent().siblings('.accordeon--item').removeClass('active');

      $('.accordeon--item').each(function () {
        if ($(this).hasClass('active')) {
          $(this).children('.accordeon--content').slideDown();
        } else {
          $(this).removeClass('active');
          $(this).children('.accordeon--content').slideUp();
        }
      });
    }
  });
};
