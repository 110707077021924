import { onReady, RunThemeExtensions } from 'wly-theme-extensions';
import { Settings } from 'luxon';

import Paginator from '@/js/vue/components/Paginator.vue';
import YoutubePlayer from '@/js/vue/components/YoutubePlayer.vue';
import EventPaginator from '@/js/vue/components/EventPaginator.vue';
import Lightbox from '@/js/vue/components/Lightbox.vue';
import AnimatedBorder from '@/js/vue/components/AnimatedBorder.vue';
import { InitializeForms } from './form.js';
import { updateActiveItem } from './navigation.ts';
import Accordion from './accordion.ts';
import Autosize from './autosize.ts';
import Swiper from './swiper.ts';
import lazySizes from 'lazysizes';
import Menu from './mmenu';
import Teich from './teich';
import MegaMenu from './mega-menu.js';

import './baguette-box';
import './googlemapsinfobox';
import './masonry';
import './tabs';

onReady(() => {
  Menu();
  updateActiveItem();
  lazySizes.init();
  Accordion();
  Autosize();
  Swiper();
  InitializeForms();
  Teich();
  MegaMenu();
});

RunThemeExtensions({
  vue: {
    components: {
      'wly-paginator': Paginator,
      'wly-event-paginator': EventPaginator,
      'wly-youtube': YoutubePlayer,
      'wly-lightbox': Lightbox,
      'wly-animated-border': AnimatedBorder,
    },
    onBootstrap: (app) => {
      Settings.defaultLocale = window.vuei18n?.locale?.toLowerCase().replace('_', '-') || 'de-ch';
    },
    i18n: {
      //
    },
  },
  alpine: {
    components: {
      //
    },
  },
  tools: {
    scrollToAnchorOptions: {
      offset: (document.querySelector('.header-main')?.clientHeight ?? 0) + 100,
    },
  },
});
