import $ from 'jquery';

export default () => {
    var $navContainer = $('.menu--mega');
    var $navItems = $('.nav-item');
    var $backdrop = $('.mega-menu__backdrop');
    var $megaMenus = $('.mega-menu');

    let isMenuOpen = false;

    function closeMegaMenu() {
        $('body').removeClass('mega-menu--open');
        $backdrop.fadeOut(300);
        $megaMenus.fadeOut(300);
        $navItems.removeClass('active-menu nav-element--inactive');
        isMenuOpen = false;
    }

    $navItems.on('click', function (e) {
        e.stopPropagation();
        var $currentNavItem = $(this);
        var $currentMegaMenu = $currentNavItem.find('.mega-menu');

        if ($currentNavItem.hasClass('active-menu')) {
            closeMegaMenu();
            return;
        }

        $navItems.removeClass('active-menu nav-element--inactive');
        $navItems.not($currentNavItem).addClass('nav-element--inactive');
        $megaMenus.fadeOut();

        if ($currentMegaMenu.length) {
            $currentNavItem.addClass('active-menu');
            $backdrop.fadeIn();
            $currentMegaMenu.fadeIn();
            $('body').addClass('mega-menu--open');
            isMenuOpen = true;
        }

        // Mega Menü Height Calc
        var t = 0;
        var childElem = $(this).closest('.nav-menu').find('.dropdown');

        $(childElem).each(function () {
            var elem = $(this);
            elem.height('auto');
            if (elem.height() > t) {
                t = elem.height();
            }
        });
        $(childElem).height(t);
    });

    $(document).on('click', function () {
        closeMegaMenu();
    });

    $('.dropdown-menu > li').hover(
        function () {
            $(this).find('.dropdown-submenu').stop(true, true).delay(500).fadeIn();
            $(this).siblings().find('.dropdown-submenu').stop(true, true).delay(500).fadeOut();
            $(this).siblings().filter('.active, .active-children').addClass('submenu--inactive');
        },
        function () {
            $(this).not('.active, .active-children').find('.dropdown-submenu').stop(true, true).delay(500).fadeOut();
            $(this).siblings('.active, .active-children').find('.dropdown-submenu').stop(true, true).delay(500).fadeIn();
            $(this).siblings().filter('.active, .active-children').removeClass('submenu--inactive');
        }
    );
};