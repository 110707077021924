<template>
  <Paginator :config="config" class="v-paginator">
    <template v-slot="{ paginator, toPostComponent, hideFilter, noEntriesFoundText, loadMore }">
      <LightboxFilter v-if="!hideFilter && filterViewMode === 'lightbox'" :paginator="paginator"/>

      <div class="v-paginator-posts-events">
        <div class="row">
          <div class="col-12 col-xl-10 offset-xl-1">
            <div class="v-paginator-events__wrapper">
              <div v-if="showAsSimpleSlider" class="v-paginator-posts__slider">
                <Slider class="event-slider">
                  <component class="swiper-slide event-slide" v-for="post in paginator.posts.value" :key="post.id"
                             :is="toPostComponent(post.id)"></component>
                </Slider>
              </div>
              <div v-else>
                <slot name="posts" v-bind="{ paginator, toPostComponent }">
                  <div v-for="(posts, date) in groupedPosts(paginator).value" :key="date"
                       class="v-paginator-posts-group">
                    <div class="v-paginator-posts-group-title h3">
                      <div class="title__inner">
                        {{ DateTime.fromSeconds(parseInt(`${date}`)).monthShort }}
                      </div>
                    </div>
                    <div class="v-paginator-posts-group-events">
                      <div class="title__line"></div>
                      <div class="v-paginator-posts-group-events__inner">
                        <component v-for="post in posts" :key="post.id" :is="toPostComponent(post.id)"></component>
                      </div>
                    </div>
                  </div>
                </slot>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="noEntriesFoundText && !paginator.posts.value.length && !paginator.loading.value">{{
          noEntriesFoundText
        }}
      </div>
      <div v-if="loadMore && paginator.hasNextPage.value" class="v-paginator-load-more">
        <div class="load-more" @click="paginator.loadMore()">
          <InlineSpinner v-if="paginator.loading.value"/>
          <span>{{ $t('paginator.more') }}</span>
          <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M.793.793a1 1 0 0 1 1.414 0L6.5 5.086 10.793.793a1 1 0 1 1 1.414 1.414l-5 5a1 1 0 0 1-1.414 0l-5-5a1 1 0 0 1 0-1.414Z"
                  fill="currentColor"/>
          </svg>
        </div>
      </div>
    </template>
  </Paginator>
</template>

<script setup lang="ts">
import {InlineSpinner, Paginator, PaginatorType} from 'wly-theme-extensions';
import LightboxFilter from '@/js/vue/components/Paginator/LightboxFilter.vue';
import {computed, toRaw} from 'vue';
import {DateTime} from 'luxon';
import Slider from '@/js/vue/components/Slider.vue';

const props = defineProps({
  config: {
    type: Object,
    required: true,
  },
  filterViewMode: {
    type: String,
    default: () => 'lightbox',
  },
  viewMode: {
    type: String,
    default: () => 'list',
  }
});

const showAsSimpleSlider = computed(() => props.viewMode === 'slider');
const groupedPosts = (paginator: PaginatorType) =>
    computed(() => {
      const posts = Object.groupBy(paginator.posts.value, ({post}: any) => {
        const start = DateTime.fromSeconds(post.event_start);

        return DateTime.fromObject({
          year: start.year,
          month: start.month,
          day: 1,
        }).toSeconds();
      });

      return toRaw(posts);
    });
</script>
