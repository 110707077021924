// @ts-ignore
import baguetteBox from 'baguettebox.js';
import Swiper, { Autoplay, Navigation, Pagination } from 'swiper';

export default () => {
  var wlySwiper = new Swiper('.swiper-container-wly', {
    modules: [Navigation, Pagination, Autoplay],
    // Optional parameters
    direction: 'horizontal',
    loop: true,
    speed: 1400,
    spaceBetween: 30,
    autoplay: {
      delay: 7000,
    },
    grabCursor: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
  });


  var wlySwiper = new Swiper('.swiper-container-image-text', {
    modules: [Navigation, Pagination, Autoplay],
    // Optional parameters
    direction: 'horizontal',
    loop: true,
    speed: 1400,
    spaceBetween: 30,
    grabCursor: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
  });

  var wlySwiper = new Swiper('.swiper-container-testimonial', {
    modules: [Navigation, Pagination, Autoplay],
    // Optional parameters
    direction: 'horizontal',
    speed: 1400,
    loop: true,
    slidesPerView: 1,
    spaceBetween: 20,
    breakpoints: {
      1201: {
        slidesPerView: 2,
        spaceBetween: 50,
      },
      993: {
        slidesPerView: 2,
        spaceBetween: 30,
      }
    },
    autoplay: {
      delay: 7000,
    },
    grabCursor: false,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
  });

  var wlySwiper = new Swiper('.swiper-container-teaserboxes', {
    modules: [Navigation, Pagination, Autoplay],
    // Optional parameters
    direction: 'horizontal',
    loop: true,
    speed: 1400,
    slidesPerView: 1,
    spaceBetween: 30,
    breakpoints: {
      601: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      993: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
      1200: {
        spaceBetween: 40,
        slidesPerView: 3,
      }
    },
    grabCursor: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  });



  let accordeonSwiper;

  function initAccordeonSwiper() {
    if (window.innerWidth < 993 && !accordeonSwiper) {
      accordeonSwiper = new Swiper('.swiper-container-sideaccordeon', {
        modules: [Pagination, Autoplay],
        direction: 'horizontal',
        loop: true,
        speed: 1400,
        spaceBetween: 15,
        breakpoints: {
          768: {
            spaceBetween: 40,
          }
        },
        grabCursor: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      });
    } else if (window.innerWidth >= 993 && accordeonSwiper) {
      accordeonSwiper.destroy(true, true);
      accordeonSwiper = null;
    }
  }

// Initial prüfen
  initAccordeonSwiper();
  window.addEventListener('resize', initAccordeonSwiper);




  document.querySelectorAll('.swiper-container-team').forEach((el) => {
    // Überprüfen, ob die Klassen 'small-slider-4' oder 'small-slider-5' vorhanden sind
    const isSmallSlider4 = el.classList.contains('small-slider-4');
    const isSmallSlider5 = el.classList.contains('small-slider-5');

    new Swiper(el, {
      modules: [Pagination, Autoplay],
      direction: 'horizontal',
      loop: true,
      speed: 1400,
      slidesPerView: isSmallSlider5 ? 2 : (isSmallSlider4 ? 2 : 1),
      spaceBetween: 20,
      breakpoints: {
        601: {
          slidesPerView: isSmallSlider5 ? 3 : (isSmallSlider4 ? 3 : 2),
        },
        993: {
          slidesPerView: isSmallSlider5 ? 4 : (isSmallSlider4 ? 3 : 2),
        },
        1201: {
          slidesPerView: isSmallSlider5 ? 5 : (isSmallSlider4 ? 4 : 3),
        }
      },
      grabCursor: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
    });
  });



};


