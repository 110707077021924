<template>
  <div class="v-lightbox" :class="[`position-${position}`]">
    <div class="v-lightbox-toggle" @click="handleToggleClick">
      <slot name="toggle"> Öffne Mich </slot>
    </div>

    <Transition name="fade">
      <div v-if="open && position !== 'fixed'" class="v-lightbox-content-background" @click="handleClose"></div>
    </Transition>

    <Teleport to="body">
      <template v-if="position === 'fixed'">
        <Transition name="fade-slide-up">
          <div v-if="open" class="v-lightbox-content-container" @click="handleClose">
            <Close v-if="showCloseButton" @click="handleClose" class="mobile" />
            <div class="container">
              <div class="v-lightbox-content" @click.stop="() => {}">
                <Close v-if="showCloseButton" @click="handleClose" class="desktop" />
                <slot name="content" />
              </div>
            </div>
          </div>
        </Transition>
      </template>
    </Teleport>
    <template v-if="position !== 'fixed'">
      <Transition name="fade-slide-up">
        <div v-if="open" class="v-lightbox-content">
          <Close v-if="showCloseButton" @click="handleClose" />
          <slot name="content" />
        </div>
      </Transition>
    </template>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref, watch } from 'vue';
import Close from '@/js/vue/components/Icons/Close.vue';

const props = defineProps({
  showCloseButton: {
    type: Boolean,
    default: () => false,
  },
  position: {
    type: String,
    default: () => 'absolute',
  },
});

const open = ref(false);

const handleEscPress = (e: KeyboardEvent) => {
  if (open.value && e.key === 'Escape') {
    e.preventDefault();
    e.stopImmediatePropagation();

    handleClose();
  }
};

const handleToggleClick = () => {
  open.value = !open.value;
};

const handleClose = () => {
  open.value = false;
};

watch(open, (open) => {
  if (open && props.position === 'fixed') {
    document.documentElement.style.overflow = 'hidden';
  } else {
    document.documentElement.style.overflow = 'visible';
  }
});

onMounted(() => {
  window.addEventListener('keydown', handleEscPress);

  return () => window.removeEventListener('keydown', handleEscPress);
});
</script>

<style lang="scss" scoped>
.v-lightbox {
  position: relative;
}

.v-lightbox-content-container {
  // We need an offset, because the dark background is part of the animated container,
  // Otherwise the would be a transparent space while the container is animated to the correct position.
  $background-animation-offset: 50px;

  position: fixed;
  top: $background-animation-offset * -1;
  left: 0;
  right: 0;
  bottom: $background-animation-offset * -1;
  z-index: 100;
  overflow-y: auto;
  background: rgb(0 0 0 / 62%);
  backdrop-filter: blur(10px);
  padding-top: responsive-value(132px + $background-animation-offset, 40px + $background-animation-offset);
  padding-bottom: responsive-value(132px + $background-animation-offset, 40px + $background-animation-offset);
  @include media(L) {
    padding: 0;
    overflow: visible;
    bottom: 0;
    top: 0;
  }

  .container {
    @include media(L) {
      max-width: none;
      padding: 0;
    }
  }
  .v-lightbox-content {
    position: relative;
  }
}

.v-lightbox-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  transform: translateY(0px);
  border-radius: 25px;
  background: $white;
  box-shadow: -5px 5px 25px 0px rgba(0, 0, 0, 0.05);
  padding: 80px 0 150px;
  opacity: 1;
  @include media(L) {
    position: fixed;
    bottom: 0;
    border-radius: 0;
    overflow: auto;
    height: 100vh;
    padding: 50px 0 150px;
  }
  @include media(S) {
    padding: 30px 0 150px;
  }
}

.v-lightbox-toggle {
  cursor: pointer;
}

.v-lightbox-content-background {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  opacity: 1;
  z-index: 99;
  background: rgb(0 0 0 / 62%);
  backdrop-filter: blur(10px);
}

.fade-enter-active,
.fade-leave-active,
.fade-slide-up-enter-active,
.fade-slide-up-leave-active {
  transition: all $transition-duration $transition-curve;
}

.fade-enter-from,
.fade-leave-to,
.fade-slide-up-enter-from,
.fade-slide-up-leave-to {
  backdrop-filter: blur(0px);
  opacity: 0;
}

.fade-slide-up-enter-from,
.fade-slide-up-leave-to {
  transform: translateY(20px);
}
</style>
